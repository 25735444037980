.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #eeeef0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.site-header-main {
  padding: 0 20px;
  max-width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
}
.site-branding {
  width: 980px !important;
  max-width: 95%;
  margin: 12px auto 0 !important;
}
.primary-text {
  color: white;
}
.header-text {
  color: #b39e51;
}
.gft-cbx {
  color: white;
}
.gft-cbx > .is-checked {
  color: #b39e51;
}
.disabled-text {
  color: lightgray;
}

// .payment-info-field {
//   color: black;
// }
// .payment-info-light {
//   color: black;
// }


.payment-info-field > label > .ms-Checkbox-checkbox {
  border: 1px solid gray;
}

.cbx-large-font {
  font-size: "24px";
}
