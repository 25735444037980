.Traveler {
  padding: 20px;
  flex: 4;
  background-color: black;
  padding: 10px;
  margin: 10px;
  border: 5px;
  align-items: auto;
}
  
.TravelerTitleText{
  display: flex;
  align-items: Left;
  color: azure;
  margin: 10px;
}
    
.DatePicker {
  align-items: auto;
  padding: 10px;
  margin: 10px;
  border: 5rem;
  text-emphasis-color: white;
}

.CalPick{
  text-emphasis-color: white;
  color: white;
}

.Textfasd{
background-color: white;
padding: 10px 10px 10px 10px;
border: 5px;
  }

.TravelerTitleText{
  padding: 20px
  

}
.Traveler{
  padding: 20px
  

}
.star {
  content: "*";
  color: rgb(164, 38, 44);
}
