.App {
  text-align: center;
  font-size: 5em;
  margin-left:10px;
  margin-right:10px;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html,
body {
    overscroll-behavior-y: contain;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#info{display:none}

.RenderHeader {
  @media all and (max-width:300px){
    #mobile{
      align-self: start;
      align-content: baseline;
    
    }
  }
    @media all and (min-width:300px){
      #mobile{
        align-self: start;
        align-content: baseline;
      }}
      @media all and (min-width:500px){
        #mobile{
          align-self: start;
          align-content: baseline;
        }}
        @media all and (min-width:760px){
          #mobile{
            align-self: start;
            align-content: baseline;
          }}
}

@media all and (max-width:300px){
  #mobile{
    line-height: 3em;
    max-height: 4em;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.site-header-main {
  padding: 0 20px;
  max-width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  
}
.site-branding {
  width: 980px !important;
  max-width: 95%;
  margin: 12px auto 0 !important;
}
.primary-text {
  color: white;
}
.header-text {
  color: #b39e51;
}
.gft-cbx {
  color: white;
}
.gft-cbx > .is-checked {
  color: #b39e51;
}
.disabled-text {
  color: lightgray;
}
.custom-logo {
  max-width: 312px !important;
}
.payment-info-field {
  color: black;
}
.payment-info-light {
  color: black;
}
.payment-info-field > div > label {
  color: black !important;
}
.payment-info-field > div > button > span > span {
  color: black !important;
}
.payment-info-field > label {
  color: black !important;
}
.payment-info-field > div > button {
  background-color: white !important;
}
.payment-info-field > label > .ms-Checkbox-checkbox {
  border: 1px solid gray;
}
.payment-info-light > label > .ms-Checkbox-checkbox {
  border: 1px solid lightgray !important;
}
.cbx-large-font {
  font-size: "24px";
}

.DetailsList {
  font-size: "40px";
}

.date-select{
  background-color: white !important;
  text-emphasis-color: black;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.myHelp {
  text-align: center;
  align-items: center;
  align-content: center;
}